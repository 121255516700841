import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  styled,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import JobCard from "../../components/Cards/JobCard";
import WorkOrderModal from "../../components/Utility/WorkOrderModal";
import JobAllocationService from "../../service/JobAllocationService"; // Import your service

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Job_Allocation = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // State for selected date
  const [jobData, setJobData] = useState([]); // State for job data
  const [unassignedList, setUnassignedList] = useState([]);

  // Fetch job data for the default date when the component mounts
  useEffect(() => {
    const fetchJobData = async () => {
      const formattedDate = selectedDate.format("YYYY-MM-DD"); // Format date

      try {
        // Fetch Job Card data
        const jobCardResponse = await JobAllocationService.GetJobCard(
          formattedDate
        );
        if (jobCardResponse.data.StatusCode === 200) {
          setJobData(jobCardResponse.data.ResultSet);
        } else {
          setJobData([]);
        }

        // Fetch Unassigned List data
        const unassignedListResponse =
          await JobAllocationService.GetUnAssignedList(formattedDate);
        if (unassignedListResponse.data.StatusCode === 200) {
          setUnassignedList(unassignedListResponse.data.ResultSet);
        } else {
          setUnassignedList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setJobData([]);
        setUnassignedList([]);
      }
    };

    fetchJobData();
  }, [selectedDate]); // Fetch data when selectedDate changes

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDateChange = async (newValue) => {
    setSelectedDate(newValue); // Update the selected date
  };

  return (
    <div>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          backgroundColor: "#f5f5f5",
          borderBottom: "1px solid #ccc",
          mb: 2,
        }}
      >
        {/* DatePicker on the left */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ fontWeight: "bold", mr: 1 }}>
            Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate} // Set selected date
              onChange={handleDateChange} // Handle date change
              renderInput={(params) => (
                <TextField {...params} size="small" sx={{ width: 150 }} />
              )}
            />
          </LocalizationProvider>
        </Box>

        {/* New Button on the right */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          sx={{
            backgroundColor: "#007bff",
            color: "white",
            textTransform: "none",
          }}
        >
          New
        </Button>
      </Box>

      {/* Grid container */}
      {/* <Grid container rowSpacing={0}> */}
        <JobCard
          jobData={jobData}
          unassignedList={unassignedList}
          selectedDate={selectedDate}
        />
      {/* </Grid> */}

      {/* Dialog */}
      <BootstrapDialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogContent>
          <WorkOrderModal onClose={handleCloseModal} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default Job_Allocation;
