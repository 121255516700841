import axios from "axios";
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

function WorkOrderModal({ onClose }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cdlJobNo, setCdlJobNo] = useState("");
  const [specification, setSpecification] = useState("");
  const [jobNoOptions, setJobNoOptions] = useState([]);

  useEffect(() => {
    const fetchJobNumbers = async () => {
      try {
        const response = await axios.get("JobAllocation/LoadJobNo");
        if (response.status === 200 && response.data.ResultSet) {
          setJobNoOptions(response.data.ResultSet.map(item => item.JOBNO));
        } else {
          alert("Failed to load job numbers");
        }
      } catch (error) {
        console.error("Error fetching job numbers:", error);
        alert("An error occurred while loading job numbers");
      }
    };
  
    fetchJobNumbers();
  }, []);

  const handleSave = async () => {
    if (!startDate || !endDate || !cdlJobNo || !specification) {
      alert("Please fill all fields");
      return;
    }

    const userConfirmed = window.confirm("Are you sure you want to save this?");
    if (!userConfirmed) return;

    const P_JCAT = cdlJobNo.slice(0, 2);
    const P_JMAIN = cdlJobNo.slice(3);

    try {
      const response = await axios.get("JobAllocation/AddNewJob", {
        params: {
          P_JCAT: P_JCAT,
          P_JMAIN: P_JMAIN,
          P_SPEC: specification,
          P_SDATE: dayjs(startDate).format("YYYY-MM-DD"),
          P_EDATE: dayjs(endDate).format("YYYY-MM-DD"),
          P_STATUS: "",
        },
      });

      if (response.status === 200) {
        alert("Record Saved Successfully!");
        onClose();
      } else {
        alert("Failed to add job: " + response.data.Result);
      }
    } catch (error) {
      console.error("Error adding job:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", mb: 2, color: "#2196F3" }}
      >
        Temporary Work Order
      </Typography>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="cdl-job-no-label">CDL Job No</InputLabel>
              <Select
                labelId="cdl-job-no-label"
                id="cdl-job-no"
                value={cdlJobNo}
                label="CDL Job No"
                onChange={(e) => setCdlJobNo(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200, // Add scrollbar if items exceed this height
                    },
                  },
                }}
              >
                {jobNoOptions.length > 0 ? (
                  jobNoOptions.map((jobNo) => (
                    <MenuItem
                      key={jobNo}
                      value={jobNo}
                      sx={{
                        fontSize: "0.875rem", // Smaller font size
                        padding: "4px 8px",   // Smaller padding for compact appearance
                      }}
                    >
                      {jobNo}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No job numbers available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <TextField
          id="specification"
          label="Specification"
          multiline
          rows={4}
          fullWidth
          value={specification}
          onChange={(e) => setSpecification(e.target.value)}
          sx={{ mb: 2 }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" autoFocus onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </>
  );
}

export default WorkOrderModal;
