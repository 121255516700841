import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2196F3",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor !== "" ? bgcolor : theme.palette.action.hover,
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  padding: 0,
  margin: 0,
  cursor: "pointer",
}));

export default function JobCard({ jobData, unassignedList, selectedDate }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRowClick = (row) => {
    setSelectedRow(row);
    navigate(`/empdetails`, {
      state: {
        EWONO: row.EWONO,
        JOBNO: row.JOBNO,
        unassignedList,
        selectedDate: selectedDate.format("YYYY-MM-DD"),
      },
    });
  };

  return (
    <Box sx={{ px: 2 }}>
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", mb: 2, color: "#2196F3" }}
      >
        Assigned Jobs
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 800,
          width: "100%",
          overflowX: "auto",
          borderRadius: "8px",
        }}
      >
        <Table
          stickyHeader
          aria-label="assigned jobs table"
          sx={{ width: "100%"}}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" >EWO No</StyledTableCell>
              <StyledTableCell align="center">Job No</StyledTableCell>
              <StyledTableCell align="center">LOC</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobData.map((row, index) => (
              <StyledTableRow
                key={index}
                onClick={() => handleRowClick(row)}
                sx={{
                  "&:hover": { backgroundColor: theme.palette.action.hover },
                }}
              >
                <StyledTableCell align="center">{row.EWONO}</StyledTableCell>
                <StyledTableCell align="center">{row.JOBNO}</StyledTableCell>
                <StyledTableCell align="center">{row.LOC}</StyledTableCell>
                <StyledTableCell align="center">{row.STS}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
