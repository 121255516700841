import React, { useEffect } from "react";
import { Box, Chip } from "@mui/material";
import { useDispatch } from "react-redux";
import AttendanceCard from "../../components/Cards/AttendanceCard";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetAttendanceCard } from "../../action/Attendance";

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 32,
    margin: theme.spacing(0.5),
  },
  datePickerContainer: {
    width: "100px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
}));

const Attendance = () => {
  const [year, setYear] = React.useState(dayjs()); 
  const [month, setMonth] = React.useState(dayjs().format("MM"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentMonth = dayjs().format("MM"); 

  useEffect(() => {
   
    const selectedYear = year ? year.year() : null;
    dispatch(GetAttendanceCard(selectedYear + "-" + month));
  }, [year, month, dispatch]);

  const handleChangeMonth = (id) => {
    setMonth(id);
    if (year !== "") {
      const selectedYear = year ? year.year() : null;
      dispatch(GetAttendanceCard(selectedYear + "-" + id));
    } else {
      toast.error("Please select year first.");
    }
  };

  const handleDateChange = (date) => {
    setYear(date);
    if (date !== "") {
      const selectedYear = date ? date.year() : null;
      dispatch(GetAttendanceCard(selectedYear + "-" + month));
    }
  };

  function CustomDatePickerContainer() {
    const yesterday = dayjs().subtract(1, "day");
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <Box className={classes.datePickerContainer}>
            <MobileDatePicker
              value={year} 
              maxDate={yesterday}
              label="Pick Year"
              views={["year"]}
              onChange={handleDateChange}
              sx={{ borderRadius: 10 }}
            />
          </Box>
        </DemoContainer>
      </LocalizationProvider>
    );
  }

  // List of months (descending order)
  const months = [
    { label: "December", value: "12" },
    { label: "November", value: "11" },
    { label: "October", value: "10" },
    { label: "September", value: "09" },
    { label: "August", value: "08" },
    { label: "July", value: "07" },
    { label: "June", value: "06" },
    { label: "May", value: "05" },
    { label: "April", value: "04" },
    { label: "March", value: "03" },
    { label: "February", value: "02" },
    { label: "January", value: "01" },
  ];

 
  const filteredMonths = months.filter((m) => m.value <= currentMonth);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mt: 1,
          mb: 1,
          mr: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#EB984E",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "8px",
            width: 70,
            height: "32px",
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            marginLeft: 1,
          }}
        >
          {CustomDatePickerContainer()}
        </Box>
        <Box
          overflow="auto"
          flex={1}
          display="flex"
          flexDirection="row"
          sx={{
            overflow: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {filteredMonths.map((chip) => (
            <Chip
              key={chip.value}
              label={chip.label}
              sx={{
                backgroundColor: month === chip.value ? "#EB984E" : "#1565c0",
                color: "#fff",
              }}
              className={classes.chip}
              clickable={true}
              onClick={() => handleChangeMonth(chip.value)}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginLeft: 1,
          marginRight: 1,
          marginBottom: "70px",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <AttendanceCard />
        </Box>
      </Box>
    </Box>
  );
};

export default Attendance;
