import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Typography,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Grid,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2196F3",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor !== "" ? bgcolor : theme.palette.action.hover,
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  padding: 0,
  margin: 0,
  cursor: "pointer",
}));

export default function EMPDetails() {
  const location = useLocation();
  const {
    EWONO,
    JOBNO,
    unassignedList: initialUnassignedList,
    selectedDate,
  } = location.state || {};

  const [unassignedList, setUnassignedList] = useState(
    initialUnassignedList || []
  );
  const [allocatedList, setAllocatedList] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAllocatedEmployees = async () => {
    try {
      const response = await axios.get("JobAllocation/GetAssignedList", {
        params: {
          P_DATE: selectedDate,
        },
      });

      if (response.data && response.data.StatusCode === 200) {
        setAllocatedList(response.data.ResultSet);
      } else {
        alert("Failed to fetch allocated employees.");
      }
    } catch (error) {
      console.error("Error fetching allocated employees:", error);
      alert("Error fetching allocated employees.");
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchAllocatedEmployees();
    }
  }, [selectedDate]);

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(employee)
        ? prevSelected.filter((e) => e.BarCodeNo !== employee.BarCodeNo)
        : [...prevSelected, employee]
    );
  };

  const saveEmployees = async () => {
    if (selectedEmployees.length === 0) {
      alert("No employees selected to save.");
      return;
    }
    const confirmSave = window.confirm(
      "Are you sure to save selected records?"
    );
    if (!confirmSave) return;
    const employeesToSave = selectedEmployees.filter(
      (employee) =>
        !allocatedList.some(
          (allocated) => allocated.AS_BarCodeNo === employee.BarCodeNo
        )
    );
    if (employeesToSave.length === 0) {
      alert("All selected employees are already allocated.");
      setSelectedEmployees([]);
      return;
    }
    try {
      for (const employee of employeesToSave) {
        const response = await axios.get("JobAllocation/SelectEmployee", {
          params: {
            P_DATE: selectedDate,
            P_TIME_IN: employee.InTime,
            P_TIME_OUT: employee.OutTime,
            P_EWO_NO: EWONO,
            P_BARCODE_CARDNO: employee.BarCodeNo,
            P_CONTINUED_STATUS: employee.Cont,
          },
        });

        if (response.data && response.data.StatusCode === 200) {
          setAllocatedList((prev) => [...prev, employee]);
          setUnassignedList((prev) =>
            prev.filter((e) => e.BarCodeNo !== employee.BarCodeNo)
          );
        } else {
          alert(`Employee ${employee.Name} could not be saved.`);
        }
      }
      setSelectedEmployees([]);
      alert("Selected employees saved successfully!");
      setTimeout(() => {
        fetchAllocatedEmployees();
      }, 500);
    } catch (error) {
      console.error("Error saving employees:", error);
      alert("Error saving employees.");
    }
  };

  const filteredUnassignedList = unassignedList.filter(
    (employee) =>
      employee.BarCodeNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Box sx={{ px: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          {EWONO} | {JOBNO} | Date: {selectedDate}
        </Typography>

        {/* Allocated Employees Section */}
        <Grid sx={{ mt: 4 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2, color: "#4caf50" }}
          >
            Allocated Employees
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 350, width: "100%", overflowY: "auto" }}
          >
            <Table stickyHeader aria-label="allocated employees table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" sx={{ width: "10%" }}>
                    Barcode No
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ width: "80%" }}>
                    Name
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "10%" }}>
                    In Time
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "10%" }}>
                    Out Time
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allocatedList.map((employee) => (
                  <StyledTableRow key={employee.AS_BarCodeNo}>
                    <StyledTableCell align="center">
                      {employee.AS_BarCodeNo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.AS_Name}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      {employee.AS_InTime}
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      {employee.AS_InTime
                        ? new Date(employee.AS_InTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hourCycle: "h23",
                          })
                        : " "}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      {employee.AS_OutTime}
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      {employee.AS_OutTime
                        ? new Date(employee.AS_OutTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hourCycle: "h23",
                          })
                        : " "}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Unassigned Employees Section */}
        <Grid sx={{ mt: 4 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2, color: "#2196F3" }}
          >
            Unassigned Employees
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 450, width: "100%", overflowY: "auto" }}
          >
            <TextField
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              sx={{ width: "100%", mb: 2, backgroundColor: "white" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {/* <TableContainer
            component={Paper}
            sx={{ maxHeight: 800, width: "100%" }}
          > */}
            <Table stickyHeader aria-label="unassigned employees table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" sx={{ width: "5%" }}>
                    Select
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1%" }}>
                    Barcode No
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ width: "79%" }}>
                    Name
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "15%" }}>
                    In Time
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">Out Time</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUnassignedList.map((employee) => (
                  <StyledTableRow key={employee.BarCodeNo}>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={selectedEmployees.includes(employee)}
                        onChange={() => handleCheckboxChange(employee)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {employee.BarCodeNo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.Name}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      {employee.InTime}
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      {employee.InTime
                        ? new Date(employee.InTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hourCycle: "h23",
                          })
                        : " "}
                    </StyledTableCell>

                    {/* <StyledTableCell align="center">
                      {employee.OutTime}
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* </TableContainer> */}
        </Grid>

        {/* Save Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button variant="contained" color="primary" onClick={saveEmployees}>
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}
