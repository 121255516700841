import axios from "axios";

const login = async (service_no,password) => {
  let formData = new FormData();
  formData.append("P_SERVICE_NO", service_no);
  formData.append("P_PASSWORD", password);
 

  let config = {
    method: "post",
    url: "Login/Login",
    data: formData,
  };

  return axios.request(config).then((response) => {
    return response;
  });
};



export default {
  login,

};
